import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useCreateGameMutation } from '../api/api';
import { Button, LinkButton } from '../components/Button';
import { Heading } from '../components/Heading';
import { Input } from '../components/Input';
import { Select } from '../components/Select';
import { TitleContentLayout } from '../layouts/TitleContentLayout';
import { HandleRequestError } from './RequestError';

function findOngoingGame(games) {
  return games.find(game => !game.closed)
}

export function hasOngoingGame(games) {
  return games.length !== 0 && games.some((game) => !game.closed);
}

function hasPermissionToCreateGame(profile, table) {
  return profile.sub === table.creaBy;
}

function ToRunningGame({ game }) {
  const url = `/table/${game.tableID}/game/${game.id}`;
  return <Heading title="Join to ongoing game">
    <LinkButton to={url}>Join to <strong>{game.title}</strong></LinkButton>
  </Heading>;
}

function CreateGameForbidden({ children }) {
  return <div><TitleContentLayout className="bg-red-400 p-4 rounded-lg">
    <TitleContentLayout.HeadingH3>Permission error</TitleContentLayout.HeadingH3>
    <p className="mb-0">You can not create game for this table</p>
  </TitleContentLayout>
    {children}
  </div>
}

function CreateGameForm({ onSubmit }) {
    return <form method="post" id="CreateGame" onSubmit={onSubmit}>
      <div className="mb-5">
        <label className="font-bold text-sm block mb-2" htmlFor="CreateGame_Title">Game title</label>
        <Input type="text" name="title" id="CreateGame_Title" required />
      </div>
      <div className="mb-3">
        <label className="font-bold text-sm block mb-2" htmlFor="CreateGame_DeckType">Deck type</label>
        <Select name="deck-type" id="CreateGame_DeckType" defaultValue="standard">
          <option value="standard">standard</option>
          <option value="fibonacci">fibonacci</option>
          <option value="tshirt">t-shirt</option>
          <option value="manday">manday</option>
        </Select>
      </div>
      <Button type="submit">Create game</Button>
    </form>
}

function CreateGameLayout({ children }) {
  return <TitleContentLayout>
    <TitleContentLayout.HeadingH3>Create game</TitleContentLayout.HeadingH3>
    {children}
  </TitleContentLayout>;
}


export function CreateGame() {
  const params = useParams();
  const navigate = useNavigate();
  const { table, profile, setGames } = useOutletContext();
  const { games } = table;

  const createGameMutation = useCreateGameMutation({
    onSuccess: ({ game }) => {
      setGames((currentGames) => {
        return [game].concat(currentGames);
      });
      navigate(`/table/${table.id}/game/${game.id}`);
    }
  });

  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const tableID = params.tableID;
    const title = formData.get('title')
    const deckType = formData.get('deck-type');

    createGameMutation.mutate({ tableID, title, deckType });
  }

  if (!hasPermissionToCreateGame(profile, table)) return <CreateGameForbidden>
    {hasOngoingGame(games) && <ToRunningGame game={findOngoingGame(games)} />}
  </CreateGameForbidden>;

  if (createGameMutation.isError) {
    return <CreateGameLayout>
      <HandleRequestError err={createGameMutation.error} />
    </CreateGameLayout>;
  }

  return <CreateGameLayout>
    <CreateGameForm onSubmit={onSubmit} />
  </CreateGameLayout>;
}
