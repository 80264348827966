import { isAbstractDeck, getCards } from '../data/cards';

function getSum(nums) {
  return nums.reduce((acc, num) => {
    return acc + num;
  }, 0);
}

function getSumAVG(votes) {
  const { sum, count } = votes.reduce((acc, { vote }) => {
    const int = Number.parseInt(vote);
    if (Number.isNaN(int)) return acc;

    acc.sum += int;
    acc.count++;
    return acc;
  }, {sum: 0, count: 0});

  if (count === 0) return null;

  return sum / count;
}

function getClosestNextCard(avg, cards) {
  if (Number.isNaN(avg)) return null;
  const intAvg = Math.ceil(avg);
  const nextCard = cards.find((card) => {
    const intCard = Number.parseInt(card);
    return intCard >= intAvg;
  });

  return nextCard || cards[cards.length - 1];
}

function getClosestNextByIndex(votes, cards) {
  const voteIndexes = votes.map(({ vote }) => {
    return cards.indexOf(vote);
  });
  const avgIndex = Math.ceil(getSum(voteIndexes) / votes.length);

  return cards[avgIndex];
}

export function getAvgText(votes, deckType) {
  if (votes.length === 0) return 'N/A';
  const avg = getSumAVG(votes);

  const nans = votes
    .filter(({ vote }) => Number.isNaN(Number.parseInt(vote)))
    .map(({ vote }) => vote);

  const cards = getCards(deckType);
  if (avg === null) {
    if (isAbstractDeck(deckType)) return [getClosestNextByIndex(votes, cards), null, null];
    if (votes.length === 1) return [votes[0].vote, null, null]
    return ['???', null, null];
  }

  return [getClosestNextCard(avg, cards), avg, nans];
}

export function GameSum({ votes, className, deckType }) {
  const [closest] = getAvgText(votes, deckType);
  return <span className={className}>{closest}</span>;
}
