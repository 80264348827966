import { useContext } from 'react';
import { Outlet } from "react-router-dom";
import ProfileContext from '../contexts/ProfileContext';
import { Login } from './Login';

export const ProtectedRoute = ({ children }) => {
  const user = useContext(ProfileContext);
  if (!user) {
    // user is not authenticated
    return <Login />;
  }

  return children || <Outlet />;
};
