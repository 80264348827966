import { useContext, useState } from 'react';
import ProfileContext from '../contexts/ProfileContext';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as UserAdd } from './assets/user-add.svg';
import { ReactComponent as UserRemove } from './assets/user-remove.svg';
import { UserInfo } from './UserThumbnail';
import { twMerge } from 'tailwind-merge';

function IconButton({ children, className, onClick, ...props }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    onClick();
    setTimeout(() => setClicked(false), 1000);
  }

  return <button className={className} onClick={handleClick} {...props}>
    {clicked
      ? <Check className="inline-block w-5 h-5 hover:scale-150 hover:text-primary-600 transition-all" />
      : children}
  </button>
}

function Controls({ canEdit, joinedState, onRejectTableUser, onAdmitTableUser, className }) {
  if (!canEdit) return null;

  const canJoin = joinedState !== 'joined';
  const canReject = joinedState !== 'rejected';
  const isPending = joinedState === 'pending';

  return <div className={className}>
    {canJoin ? <IconButton onClick={onAdmitTableUser} title={isPending ? 'waiting for approval' : 'let the user join to the table'}>
      <UserAdd className="inline-block w-5 h-5 hover:scale-150 hover:text-primary-600 transition-all" />
    </IconButton> : null}
    {canReject ? <IconButton onClick={onRejectTableUser} title={isPending ? 'waiting for approval' : 'ban user from table'}>
       <UserRemove className="inline-block w-5 h-5 hover:scale-150 hover:text-primary-600 transition-all" />
    </IconButton> : null}
  </div>

}

function ListItem({ user, canEdit, onRejectTableUser, onAdmitTableUser, className, ...props }) {
  return <li className={className} {...props}>
    <Controls
      canEdit={canEdit}
      joinedState={user.isJoined}
      onRejectTableUser={onRejectTableUser}
      onAdmitTableUser={onAdmitTableUser}
      className="float-left mr-2"
    />
    <UserInfo user={user} className="inline-block" />
  </li>;
}

export function TableUsers({ users, canEdit, onRejectTableUser, onAdmitTableUser, className }) {
  const profile = useContext(ProfileContext);

  const pendingUsers = users.filter((u) => u.isJoined === 'pending');
  const bannedUsers = users.filter((u) => u.isJoined === 'rejected');
  const joinedUsers = users.filter((u) => u.isJoined === 'joined');

  const toListItem = (u) => <ListItem key={u.sub}
        canEdit={canEdit && profile.sub !== u.sub}
        onRejectTableUser={() => onRejectTableUser(u.sub)}
        onAdmitTableUser={() => onAdmitTableUser(u.sub)}
        user={u}
        className="mb-4"
      />;

  const classes = twMerge("grid grid-cols-3 w-full md:block", className);

  return <ul className={classes}>
    {pendingUsers.length > 0 ? <li><h5 className="text-lg mb-2">Pending</h5>
      <ul className="mb-4">{pendingUsers.map(toListItem)}</ul>
    </li> : null}
    {joinedUsers.length > 0 ? <li><h5 className="text-lg mb-2">Joined</h5>
      <ul className="mb-4">{joinedUsers.map(toListItem)}</ul>
    </li> : null}
    { bannedUsers.length > 0 ? <li><h5 className="text-xl mb-2">Banned</h5>
      <ul className="mb-4">{bannedUsers.map(toListItem)}</ul>
    </li> : null}
  </ul>;
}
