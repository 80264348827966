import { useContext } from "react";
import ProfileContext from "../contexts/ProfileContext";
import { TitleContentLayout } from "../layouts/TitleContentLayout";
import { BillingInfo } from "../components/BillingInfo";
import { Profile } from "../components/Profile";

export default function Account() {
  const profile = useContext(ProfileContext);

  return (
    <TitleContentLayout>
      <TitleContentLayout.Heading>Account</TitleContentLayout.Heading>

      <Profile profile={profile} />
      <BillingInfo />

    </TitleContentLayout>
  );
}
