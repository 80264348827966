import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

export const BUTTON_CLASSES = `
      inline-block
      text-base
      text-white
      font-normal
      bg-primary-600
      border
      border-primary-700
      cursor-pointer
      ring-0
      px-2
      py-1.5
      shadow-md
      rounded
      focus:outline-none
      focus:ring-2
      focus:ring-offset-2
      focus:ring-primary-600
      hover:ring-2
      hover:ring-offset-2
      hover:ring-primary-400
      transition-all
`;

export function Button({ children, className, ...props }) {
  return <button className={twMerge(BUTTON_CLASSES, className)} {...props}>
    {children}
  </button>;
}

export function LinkButton({ children, className, ...props }) {
    return <Link className={twMerge(BUTTON_CLASSES, className)} {...props}>{children}</Link>;
}
