import { Link } from 'react-router-dom';

export function InfoCardTitle({ href, children, className }) {
  if (href) {
    return <Link className={className} to={href}>{children}</Link>;
  }

  return <span className={className}>{children}</span>;
}
export function InfoCard({ children }) {
  return <div className="border shadow rounded flex flex-col">
    { children }
  </div>;
}
