function getIcon(label) {
  switch (label) {
    case 'locked':
      return '🔒';
    case 'unlocked':
      return '🔓';
    case 'calendar':
      return '📅';
    case 'deck':
      return '🃏';
    case 'vote':
      return '✓';
    case 'bunny':
      return '🐰';
    case 't-shirt':
      return '👕';
    case 'manday':
      return '👨‍💻';
    case 'close':
      return '❌';
    default:
      return null;
  }
}
export function Emoji({ label, title, className }) {
  const icon = getIcon(label);

  return <span className={className} title={title}>{icon}</span>;
}
