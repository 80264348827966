import { ReactComponent as UserImage } from './assets/user.svg';
import { hashCode, intToRGB } from '../colors';
import { twMerge } from 'tailwind-merge';
// import './UserThumbnail/UserThumbnail.css'

function userColor(username) {
  return '#' + intToRGB(hashCode(username));
}

export function UserThumbnail({ user, className }) {
  if (user.picture) {
    return <img className={className} referrerPolicy="no-referrer" src={user.picture} alt={user.name} />;
  }

  const style = { color: userColor(user.sub) };
  return <UserImage style={style} className={className} />;
}

export function UserInfo({ user, className, children }) {
  const classes = twMerge(`
    shadow
    ring-1
    ring-black/5
    h-6
    lg:h-8
    bg-white
    max-w-min
    rounded-lg
    group`, className);
  return <div className={classes}>
    <div className="flex items-center relative overflow-hidden h-full w-full">
      <UserThumbnail user={user} className="rounded-full absolute h-6 l-6 lg:h-9 lg:w-9 lg:-left-2 -left-1.5" />
      <span title={user.name} className="pl-6 lg:pl-10 pr-4 text-xs lg:text-sm whitespace-nowrap max-w-fit overflow-hidden text-ellipsis">{user.name}</span>
    </div>
    {children}
  </div>;


}
