import { EditingControls } from './Game/EditingControls';
import { EditTitle } from './EditableTitle';
import { GameSum } from './GameSum';
import { DeckType } from './DeckType';
import { twMerge } from 'tailwind-merge'
import { Button } from '../components/Button';
import { CopyButton } from '../components/CopyButton';

function GameStat({ game }) {
  if (!game.closed) return <div><span className="font-bold">{game.votes.length}</span> votes so far</div>;
  return <GameSum votes={game.votes} deckType={game.deckType} />;
}

function getShareLink(tableID, gameID) {
  return `${window.location.origin}/table/${tableID}/game/${gameID}`;
}

function GameTitle({ canEdit, game, tableID, onGameTitleChange, className }) {
  if (!canEdit) return <h3 className={className}>{game.title} <CopyButton text={getShareLink(tableID, game.id)} /></h3>;
  return <h3 className={twMerge('group', 'mr-2', className)}>
    <EditTitle
      title={game.title}
      id={game.id}
      onSubmit={onGameTitleChange}
    >{game.title} <CopyButton text={getShareLink(tableID, game.id)}/></EditTitle>
  </h3>
}

function DeleteGameButton({ onClick, enabled }) {
  if (!enabled) return null;
  return <Button className="text-sm bg-red-600 border-red-700 hover:ring-red-600" onClick={onClick}>Delete</Button>;
}

export default function Game({
  game,
  table,
  profile,
  onVote,
  onDeleteVote,
  onRevealCard,
  onGameTitleChange,
  onDeleteGame,
  canEdit,
}) {
  if (!game) return null;

  return <div className="game">
    <header className="flex justify-between mb-2">
      <GameTitle
        className="grow text-lg lg:text-xl"
        canEdit={canEdit}
        game={game}
        tableID={table.id}
        onGameTitleChange={onGameTitleChange}
      />
      <div className="flex flex-col text-gray-600 text-right font-light">
        <span><DeckType deckType={game.deckType} /> {game.deckType}</span>
        <GameStat game={game} />
        <DeleteGameButton enabled={false} onClick={onDeleteGame} />
      </div>
    </header>
    <EditingControls
      game={game}
      table={table}
      onSelect={onVote}
      onRevealCard={onRevealCard}
      onDeleteVote={onDeleteVote}
      profile={profile}
    />
  </div>;
}
