export const tShirtCards = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '?'];
export const standardCards = [
  '☕',
  '?',
  '0',
  '1/2',
  '1',
  '2',
  '3',
  '5',
  '8',
  '13',
  '20',
  '40',
  '100',
  '♾️',
];
export const fibonacciCards = [
  '?',
  '0',
  '1/2',
  '1',
  '2',
  '3',
  '5',
  '8',
  '13',
  '21',
  '34',
  '55',
  '89',
];

export const mandayCards = [
  '☕',
  '?',
  '0',
  '1/2',
  '1',
  '2',
  '3',
  '5',
  '8',
  '10',
  '15',
  '♾️',
];

export function getCards(deckType) {
  switch (deckType) {
    case 'fibonacci':
      return fibonacciCards;
    case 'tshirt':
      return tShirtCards;
    case 'manday':
      return mandayCards;
    default:
      return standardCards;
  }
}


export function isAbstractDeck(deckType) {
  return deckType === 'tshirt';
}
