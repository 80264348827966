import {
  useMutation
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { createTable } from '../api/api';
import { TitleContentLayout } from '../layouts/TitleContentLayout';
import { Button } from './Button';
import { HandleRequestError } from './RequestError';

function CreateTableForm({ onSubmit }) {
  return <form method="post" id="CreateTable" onSubmit={onSubmit}>
    <div className="mb-3">
      <label className="font-bold text-sm block mb-2" htmlFor="CreateTable_Title">Table title</label>
      <input
        className="block w-full rounded-md py-1.5 px-2
        text-gray-900 border-0 shadow-sm
        outline-0
        ring-1 ring-inset ring-gray-300
        focus:ring-1 focus:ring-inset focus:ring-primary-600
        placeholder:text-gray-400
        sm:text-sm sm:leading-6"
        type="text" name="title" id="CreateTable_Title" required />
    </div>
    <Button type="submit">Create table</Button>
  </form>;
}

function CreateTableLayout({ children }) {
  return <TitleContentLayout>
    <TitleContentLayout.Heading>Create table</TitleContentLayout.Heading>
    {children}
  </TitleContentLayout>;
}

export function CreateTable() {
  const navigate = useNavigate()
  const createTableMutation = useMutation({
    mutationFn: ({ title }) => createTable(title),
    onSuccess: ({ table }) => {
      navigate(`/table/${table.id}`);
    }
  });

  async function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.nativeEvent.target);
    const title = formData.get('title');
    createTableMutation.mutate({ title });
  }

  if (createTableMutation.isError) {
    return <CreateTableLayout>
      <HandleRequestError err={createTableMutation.error} />
    </CreateTableLayout>;
  }

  return <CreateTableLayout>
    <CreateTableForm onSubmit={onSubmit} />
  </CreateTableLayout>;
}
