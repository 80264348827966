import { useFetchBillingInfo } from '../api/api';
import { BUTTON_CLASSES } from './Button';
import { twMerge } from 'tailwind-merge';

function InactivePlan({ isCurrentPlan, currency }) {
  const href = `/api/subscribe?plan=pro&currency=${currency || ''}`;
  return (
    <div>
      <h3 className="text-xl font-bold mb-2">Billing</h3>
      <p className="mb-4">You are not subscribed to any plan.</p>
      <a className={twMerge(BUTTON_CLASSES, "mt-10 w-full md:w-auo text-center")} href={href}>Buy plan</a>
    </div>
  );
}

export function BillingInfo({ className }) {

  const { data } = useFetchBillingInfo();

  if (!data) return null;

  const status = data.status;

  if (status === 'inactive') {
    return <InactivePlan className={className} isCurrentPlan={false} currency={data.currency} />;
  }
  const nextBillingDate = new Date(data.activeUntil); // Convert timestamp to date
  const planAmount = data.amount / 100; // Amount is in cents, convert to dollars
  const planCurrency = data.currency.toUpperCase();

  return (
    <div className={className}>
      <h3 className="text-xl font-bold mb-2">Billing</h3>
      <table className="w-full table-fixed mb-4">
        <tbody>
          {nextBillingDate && <tr>
            <td className="w-1/4 py-2">Next billing date:</td>
            <td className="text-gray-800"><time datetime={nextBillingDate.toISOString()}>{nextBillingDate.toLocaleDateString()}</time></td>
          </tr> }
          <tr>
            <td className="py-2">Amount:</td>
            <td className="text-gray-800">{planAmount} {planCurrency}</td>
          </tr>
          <tr>
            <td className="py-2">Status:</td>
            <td className="text-gray-800">{status}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <a href={data.latestInvoiceURL} className="underline hover:no-underline">Download invoice</a>
        <a href="/api/billing/portal" className={twMerge(BUTTON_CLASSES, "ml-2")}>Manage Billing</a>
      </div>
    </div>
  );
}
