import { twMerge } from 'tailwind-merge';
import Card from './Card';
import { Emoji } from './Emoji';
import { UserInfo } from './UserThumbnail';

function canDelete(profile, user, closed) {
  return profile.sub === user.sub && !closed;
}

function cardWidth(size) {
  switch (size) {
    case 'sm':
      return 'w-16';
    case 'm':
      return 'w-20';
    case 'l':
      return 'w-24';
    case 'xl':
      return 'w-32';
    case 'xxl':
      return 'w-36';
    default:
      return 'w-24';
  }
}

function cardHeight(size) {
  switch (size) {
    case 'sm':
      return 'h-24';
    case 'm':
      return 'h-32';
    case 'l':
      return 'h-36';
    case 'xl':
      return 'h-48';
    case 'xxl':
      return 'h-52';
    default:
      return 'h-36';
  }
}

function cardFontSize(size) {
  switch (size) {
    case 'sm':
      return 'text-3xl';
    case 'm':
      return 'text-4xl';
    case 'l':
      return 'text-5xl';
    case 'xl':
      return 'text-6xl';
    case 'xxl':
      return 'text-7xl';
    default:
      return 'text-5xl';
  }
}

function cardPadding(size) {
  switch (size) {
    case 'sm':
      return 'p-1';
    case 'm':
      return 'p-1.75';
    case 'l':
      return 'p-2';
    case 'xl':
      return 'p-2.5';
    case 'xxl':
      return 'p-3';
    default:
      return 'p-2';
  }
}

function cardSizes(breakpointPrefix, size) {
  switch (size) {
    case 'small':
      return `${breakpointPrefix}:w-16 ${breakpointPrefix}:h-24`;
    case 'medium':
    case 'large':
      return `${breakpointPrefix}:w-20 ${breakpointPrefix}:h-32`;
    default:
      return `${breakpointPrefix}:w-24 ${breakpointPrefix}:h-36`;
  }
}

function DeleteVoteButton({ onDeleteVote, className }) {
  return <button type="button" className={className} aria-label="Close" onClick={onDeleteVote}><Emoji label="close" title="close" /></button>
}

function CardWithAuthor({ vote, user, canDelete, selected, showBack, onSelect, onDeleteVote, className }) {
  return <div className={twMerge('text-center w-16 relative', className)} key={`sm-${user.sub}`}>
    <Card
      className="mx-auto w-16 h-24 text-xl p-1.5 rounded-lg mb-4 text-gray-600 shadow"
      showBack={showBack}
      selected={selected}
      value={vote}
      onSelect={onSelect}
    />
    <UserInfo user={user} className="w-full" />
    {canDelete ? <DeleteVoteButton onDeleteVote={onDeleteVote} className="absolute -top-4 -right-4" /> : null}
  </div>;
}

export function ListOfCardsWithAuthor({ closed, cards, onSelect, onDeleteVote, revealed, profile }) {
  return <div className="flex justify-evenly flex-wrap">
    {cards.map(({vote, user}) => <CardWithAuthor
      key={user.sub}
      className="mb-12 mx-4"
      vote={vote}
      canDelete={canDelete(profile, user, closed)}
      user={user}
      showBack={!revealed && profile.sub !== user.sub}
      selected={profile.sub === user.sub}
      onSelect={onSelect}
      onDeleteVote={onDeleteVote}
    />)}
  </div>;
}

export function ListOfCards({ cards, onSelect, selected }) {
  return (
    <div className="flex justify-evenly flex-wrap">
    {cards.map((value) =>
      <Card
        className="w-32 h-48 text-5xl p-2.5 m-2"
        key={value}
        selected={selected === value}
        value={value}
        onSelect={onSelect} />
      )}
  </div>
  );
}
