import { TitleContentLayout } from '../layouts/TitleContentLayout';
import { twMerge } from "tailwind-merge";

function getRequestErrorMessage(err) {
  switch (err?.response?.status) {
    case 404:
      if (err.message === 'Load table error') {
        return 'Table not found'
      }
      return 'Not found'
    case 403:
      return 'Forbidden';
    default:
      return err.body?.message || 'An error occured'
  }
}

function getErrorMessage(err) {
  switch (err.message) {
    case 'Game not found':
      return 'Game not found';
    default:
      return 'An error occured'
  }
}

function getErrorTitle(err) {
  if (err.message) {
    return err.message;
  }

  return 'Unknown error';
}

export function HandleRequestError({ err, className }) {
  return <TitleContentLayout className={twMerge('handle-request-error bg-red-300 p-4 rounded-lg', className)}>
    <TitleContentLayout.HeadingH3>{getErrorTitle(err)}</TitleContentLayout.HeadingH3>
    <p className="mb-0">{getRequestErrorMessage(err)}</p>
  </TitleContentLayout>;
}

export function HandleError({ err }) {
  return <TitleContentLayout>
    <TitleContentLayout.HeadingH3>{getErrorTitle(err)}</TitleContentLayout.HeadingH3>
    <p className="mb-0">{getErrorMessage(err)}</p>
  </TitleContentLayout>;
}
