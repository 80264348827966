import { TitleContentLayout } from '../layouts/TitleContentLayout';
import { Button, BUTTON_CLASSES } from './Button';
import { Input } from './Input';
import { twMerge } from 'tailwind-merge';

function GuestLogin({ className }) {
  return <TitleContentLayout className={twMerge("text-center", className)}>
    <TitleContentLayout.Heading>Guest Login</TitleContentLayout.Heading>
    <form action="/api/guest-login" method="POST" className="max-w-md mx-auto lg:px-4">
      <label htmlFor="username" className="font-bold mb-2 block text-sm lg:text-base">Username</label>
      <Input type="text" name="username" id="username" className="mb-2" pattern="^[^ ][ -~]*[^ ]$" minlength="3" maxlength="20" required />
      <p className="text-gray-500 mb-4 text-sm lg:text-sm">Username must be between 3 and 20 characters and contain printable characters. Please do not start and end the name with space.</p>
      <input type="hidden" name="returnTo" value={window.location.pathname} />
      <Button className="text-center px-3" type="submit">Guest Login</Button>
    </form>

    <article className="text-gray-800 mt-8 text-sm lg:text-base">
    <p className="mb-3">With Guest Login, you can enjoy the full functionality of PlanningPoker.app without registering an account. Simply enter your username and start estimating!</p>

    <p className="mb-3">Guest logins are temporary and once your session expired, you won't be able to see your estimations again.</p>
    </article>
  </TitleContentLayout>
}

function RegularLogin({ className }) {
  const url = "/api/login?returnTo=" + encodeURIComponent(window.location.pathname);
  return <TitleContentLayout className={twMerge("text-center", className)}>
    <TitleContentLayout.Heading>Log in</TitleContentLayout.Heading>
    <div>
      <a href={url} className={twMerge(BUTTON_CLASSES, "text-center px-3")}>Log in with Auth0</a>
    </div>

    <article className="text-gray-800 mt-8 text-sm lg:text-base">
      <p>
        Auth0 integration offers the ability to view your estimation history and opt for extra features, providing a straightforward and tailored experience for your agile planning needs.
        </p>
    </article>
  </TitleContentLayout>
}

export function Login({ className }) {
  return <div className={twMerge("grid divide-y divide-gray-300 lg:divide-y-0 lg:divide-x lg:grid-cols-2", className)}>
    <RegularLogin className="p-4 lg:p-8 " />
    <GuestLogin className="p-4 lg:p-8" />
  </div>;
}
