import { Link } from 'react-router-dom';
import { useFetchUserTables } from '../api/api';
import { LinkButton } from '../components/Button';
import { FormattedDate } from '../components/DateDisplay';
import { Heading } from '../components/Heading';
import { TitleContentLayout } from '../layouts/TitleContentLayout';
import { GameList } from '../components/GameList';

function Table({ games, creaOn, id, title, profile }) {
  return (
  <div className="mb-6">
      <h4 className="mb-7 flex justify-between border-b">
        <Link
          className="font-light text-gray-700 text-2xl mr-3 hover:text-primary-600 transition-colors"
          to={`/table/${id}`}>{ title }</Link>
        <FormattedDate date={creaOn} />
      </h4>
      <GameList games={games} profile={profile} />
  </div>
  );
}

function TableListEmpty() {
  return <Heading title="You have no tables yet.">
      <LinkButton to="/table" className="mt-5">Create table</LinkButton>
  </Heading>;
}

function TableList({tables, profile}) {
  if (!tables || tables.length === 0) {
    return <TableListEmpty />;
  }

  const renderedTables = tables.map((tbl) => <Table
    games={tbl.games}
    creaOn={tbl.creaOn}
    title={tbl.title}
    id={tbl.id}
    table={tbl}
    profile={profile}
    key={tbl.id}
  />);

  return <TitleContentLayout className="px-0">
    <TitleContentLayout.Heading>
      <LinkButton to="/table" className="float-right">Create table</LinkButton> Your tables
    </TitleContentLayout.Heading>
    <div className="mt-5">{renderedTables}</div>
  </TitleContentLayout>;
}

export default function Tables({profile}) {
  const { data } = useFetchUserTables();

  if (!data) return null;

  const { tables } = data;
  if (!tables) return null;
  return (
      <TableList tables={tables} profile={profile} />
  );
}
