import {
  useQueryClient,
} from '@tanstack/react-query';
import React from 'react';

export function useWss (url) {
  const PING_INTERVAL = 30000;
  const PONG_TIMEOUT = 30000;
  const queryClient = useQueryClient()
  React.useEffect(() => {
    const websocket = new WebSocket(url)
    let pongTimeout = null;
    let pingTimeout = null;

    function onPongTimeout() {
      websocket.close();
    }

    function ping() {
      websocket.send('ping');
      clearTimeout(pingTimeout);
      pongTimeout = setTimeout(onPongTimeout, PONG_TIMEOUT);
    }
    websocket.onopen = () => {
      ping();
    };

    websocket.onmessage = (event) => {
      if (event.data === 'pong') {
        clearTimeout(pongTimeout);
        pingTimeout = setTimeout(ping, PING_INTERVAL);
        return;
      }

      const data = JSON.parse(event.data);
      const queryKey = [...data.entity, data.id].filter(Boolean);
      queryClient.invalidateQueries({ queryKey });
    };

    websocket.onerror = (event) => {
      console.error('websocket error', event);
      websocket.close();
    };

    return (e) => {
      clearTimeout(pongTimeout);
      clearTimeout(pingTimeout);
      websocket.close();
    }
  }, [queryClient, url])
}
