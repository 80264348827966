import { useOutletContext, useParams } from 'react-router-dom';
import Game from '../components/Game';
import { HandleError } from '../components/RequestError';

function getGameNotFoundError() {
  const err = new Error('Game not found');
  return err;
}

export default function ShowGame() {
  const { tableID, gameID } = useParams();
  const { table, onVote, onRevealCard, onGameTitleChange, onDeleteGame, profile } = useOutletContext();

  if (!table) return null;
  const { games } = table;

  const game = games.find((game) => game.id === gameID);
  if (!game) return <HandleError err={getGameNotFoundError()} />
  return (<Game
    game={game}
    canEdit={game.creaBy === profile.sub}
    onVote={game.closed ? null : onVote}
    onDeleteVote={() => onVote(null)}
    onDeleteGame={onDeleteGame}
    onRevealCard={onRevealCard}
    onGameTitleChange={(e) => onGameTitleChange(e.target.elements[0].value)}
    profile={profile}
    tableID={tableID}
    table={table}
  />);
}
