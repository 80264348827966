import { twMerge } from 'tailwind-merge';
export const SELECT_CLASSES = `
    bg-white
    leading-6
    block w-full rounded-md py-1.5 px-2
    text-gray-900 border-0 shadow-sm
    outline-0
    ring-1 ring-inset ring-gray-300
    focus:ring-1 focus:ring-inset focus:ring-primary-600
    placeholder:text-gray-400
    sm:text-sm sm:leading-6
`;

export function Select({ children, className, ...props }) {
  return <select className={twMerge(SELECT_CLASSES, className)} {...props}>{children}</select>
}
