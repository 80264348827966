import { ListOfCardsWithAuthor } from '../ListOfCards';

export function Votes({ game, profile, selectedVote, revealed, onDeleteVote }) {
  const votes = game.votes;
  if (votes.length === 0) return null;
  return <div className="votes">
    <ListOfCardsWithAuthor
      closed={game.closed}
      cards={votes}
      onSelect={() => {}}
      onDeleteVote={onDeleteVote}
      profile={profile}
      selected={selectedVote?.vote}
      isSmall={false}
      revealed={revealed}/>
  </div>;
}
