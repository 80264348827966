import { twMerge } from 'tailwind-merge';

function FrontSide({value, onSelect, className, children}) {
  const classes = twMerge('cardv3 border border-blue-400 rounded shadow-lg cursor-pointer', className);

  return (
    <div onClick={() => onSelect(value)} className={twMerge(classes, 'pattern-half-rombes')}>
      {children}
    </div>
  );
}

function BackSide({className}) {
  const classes = twMerge('cardv3 p-4 border border-blue-400 rounded shadow-lg cursor-pointer', className);
  return <div className={classes}>
    <div className="w-full h-full pattern-half-rombes border border-blue-300"></div>
  </div>
}

export default function Card({value, onSelect, selected, showBack, className}) {
  const classes = twMerge('select-none hover:scale-110 transition-transform', selected ? 'card--selected border-amber-400' : '', className);
  if (showBack) {
    return <BackSide className={classes} />;
  }

  const valueClasses = twMerge('shadow-inner shadow-gray-400 w-full h-full bg-white grid justify-center content-center border', selected ? 'border-amber-400' : 'border-blue-400');



  return <FrontSide value={value} onSelect={onSelect} className={classes}>
      <div className={valueClasses}>{value}</div>
  </FrontSide>;
}
