import {
  useQuery
} from '@tanstack/react-query';

export function useFetch(url, {queryKey}) {
  return useQuery({
    queryKey,
    queryFn: () => fetch(url)
      .then(async (res) => {
        const data = await res.json();
        if (res.ok) return data;

        const err = new Error(data.message);
        err.response = res;
        err.body = data;
        return Promise.reject(err);
      }),
  });
}

export function useFetchDataKey(url, key, params) {
  return useQuery({
    ...params,
    queryFn: () => fetch(url)
      .then(async (res) => {
        const data = await res.json()
        if (res.ok) return data[key];
        const err = new Error(data.message);
        err.response = res;
        err.body = data;
        return Promise.reject(err);
      }),
  });
}
