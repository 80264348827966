import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

function getTimeoutNumber(timeout) {
  const timeoutNumber = Number.parseInt(timeout);
  if (Number.isNaN(timeoutNumber)) {
    return 5000;
  }

  return timeoutNumber;
}

export function Notification({ children, className, timeout = 5000 }) {
  const [show, setShow] = useState(true);

  let timeoutId = null;

  function close() {
    if (timeoutId) clearTimeout(timeoutId);
    setShow(false);
  }

  if (!show) return null;

  const classes = twMerge("relative px-4 py-3", className);


  const timeoutNumber = getTimeoutNumber(timeout);
  if (timeoutNumber > 0) {
    timeoutId = setTimeout(close, timeoutNumber);
  }

  return <div className={classes} role="alert">
    <button className="absolute top-0 right-0 px-4 py-3 z hover:underline" onClick={close}>close</button>
    {children}
  </div>
}

export function ErrorNotification({ children, className, ...props }) {
  return <Notification className={twMerge("bg-red-100 border border-red-400 text-red-700 px-0 py-0 rounded relative", className)} {...props}>
    {children}
  </Notification>
}

export function SuccessNotification({ children, className, ...props }) {
  return <Notification className={twMerge("bg-green-100 border border-green-400 text-green-700 px-0 py-0 rounded relative", className)} {...props}>
    {children}
  </Notification>
}
