export function Profile({ profile }) {
  return <table className="w-full mb-4">
    <tbody>
      <tr>
        <td className="w-1/4 py-2">Name:</td>
        <td>{profile.name || profile.nickname}</td>
      </tr>
      <tr>
        <td className="py-2">Email:</td>
        <td>{profile.email}</td>
      </tr>
      <tr>
        <td className="py-2">Subscription Plan Name:</td>
        <td>{profile.product}</td>
      </tr>
    </tbody>
  </table>
}
