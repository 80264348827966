import { twMerge } from 'tailwind-merge';
export function TitleContentLayout({ className, children }) {
  return <div className={twMerge('2xl:container 2xl:mx-auto bg-white pb-7', className)}>
    {children}
  </div>;
}

TitleContentLayout.Heading = ({ className, children }) => {
  return <h2 className={twMerge('text-lg lg:text-3xl font-normal mb-5', className)}>{children}</h2>
    ;
}

TitleContentLayout.HeadingH3 = ({ className, children }) => {
  return <h3 className={twMerge('text-lg lg:text-2xl font-normal mb-2', className)}>{children}</h3>
}
