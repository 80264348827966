import { getAvgText } from './GameSum';

function NanRow({ nans }) {
  if (nans === null || nans.length === 0) return null;
  return <tr>
    <td className="py-3">N/A</td>
    <td className="text-right">{nans.join(' ')}</td>
  </tr>;
}

export function VotesTable({ votes, gameID, deckType, className }) {
  if (votes.length === 0) return null;

  const [closestCard, , nans] = getAvgText(votes, deckType);

  return <div className={className}>
    <table className="table-fixed border-collapse w-full my-4">
      <thead>
        <tr className="border-b text-s">
          <th className="text-left font-bold pb-3" scope="col">User name</th>
          <th className="text-right font-bold pb-3" scope="col">Vote</th>
        </tr>
      </thead>
      <tbody>
      {votes.map(({ vote, user }) => (
        <tr key={gameID + user.sub} className="border-b text-s hover:bg-gray-100 transition-colors">
          <td className="py-3 pr-3">{user.name}</td>
          <td className="text-right">{vote}</td>
        </tr>
      ))}
      </tbody>
      <tfoot className="font-bold text-s hover:bg-gray-100">
        <tr className="hover:bg-gray-100">
          <td className="py-3">Closest Card</td>
          <td className="text-right">{closestCard}</td>
        </tr>
        <NanRow nans={nans} />
      </tfoot>
    </table>
    </div>
  ;
}

