import { useState } from 'react';
import { generateId } from '../lib/generateId';
import { Button } from './Button';
import { Input } from './Input';

export function EditTitle({ title, children, onSubmit }) {
  const [editing, setEditing] = useState(false);
  const [id] = useState(generateId());
  function onKeyup(e) {
    if (e.key === 'Escape') {
      setEditing(false);
    }
  }

  async function save(e) {
    e.preventDefault();
    await onSubmit(e);
    setEditing(false);
  }

  if (editing) {
    return <form onSubmit={save}>
      <div className="mb-2">
        <Input id={id} name="title" defaultValue={title} onKeyUp={onKeyup} />
      </div>
      <Button type="submit" className="mr-2">Save</Button>
      <Button
        className="
          focus:ring-gray-300
          text-black
          border-gray-400
          border
          bg-gray-300
        " onClick={() => setEditing(false)}>Cancel</Button>
    </form>
  }
  return <>
    <span className="group">{children}
    <button
      className="
        invisible
        hover:visible
        group-hover:visible
        text-base
        text-primary-600
        hover:text-primary-900
        transition-colors
        cursor-pointer
        font-normal
        ml-2"
      onClick={() => setEditing(true)}
    >edit</button></span>
  </>
}
