import { Emoji } from './Emoji';

export function DeckType({ deckType, className }) {

  switch (deckType) {
    case 'fibonacci':
      return <Emoji label="bunny" title="fibonacci" className={className} />;
    case 'tshirt':
      return <Emoji label="t-shirt" title="t-shirt" className={className} />;
    case 'manday':
      return <Emoji label="manday" title="manday" className={className} />;
    default:
      return <Emoji label="deck" title="standard" className={className} />;
  }
}
