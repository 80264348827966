import {
  useMutation, useQuery
} from '@tanstack/react-query';
import { useFetch, useFetchDataKey } from '../hooks/useFetch';
import { HttpError } from './errors';

const PREFIX = '/api';

export async function createGame(tableID, title, deckType) {
  const r = await fetch(`${PREFIX}/table/${tableID}/game`, {
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ title, deckType }),
  });
  const res = await r.json();
  if (r.ok) return res;
  const err = new Error('Create game error');
  err.response = r;
  err.body = res;
  throw err;
}

export function useCreateGameMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, title, deckType }) => createGame(tableID, title, deckType),
    onSuccess,
  });
}

export function useDeleteGameMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, gameID }) => deleteGame(tableID, gameID),
    onSuccess,
  });
}

export function useSetGameTitleMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, gameID, title }) => setGameTitle(tableID, gameID, title),
    onSuccess,
  });
}

export function useVoteMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, gameID, vote }) => sendVote(tableID, gameID, vote),
    onSuccess,
  });
}

export function useCloseGameMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, gameID }) => closeGame(tableID, gameID),
    onSuccess,
  });
}

export function useRejectUserJoinMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, userID }) => rejectUserJoin(tableID, userID),
    onSuccess,
  });
}

export function useRequestTableJoinMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID }) => requestTableJoin(tableID),
    onSuccess,
  });
}

export function useSetTableTitleMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, title }) => setTableTitle(tableID, title),
    onSuccess,
  });
}

export function useAdmitUserJoinMutation({onSuccess}) {
  return useMutation({
    mutationFn: ({ tableID, userID }) => admitUserJoin(tableID, userID),
    onSuccess,
  });
}

export function useLoadTable(tableID) {
  return useFetchDataKey(`${PREFIX}/table/${tableID}`, 'table', {
    queryKey: ['table', tableID],
    retry: (failureCount, err) => {
      if (err.response.status === 403) return false;
      return failureCount < 3;
    }
  });
}

export async function sendVote(tableID, gameID, vote) {
  const r = await fetch(`${PREFIX}/table/${tableID}/game/${gameID}/vote`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ vote, }),
  });
  const res = await r.json();

  return (r.ok) ? res : Promise.reject(new HttpError(r, res));
}

export async function closeGame(tableID, gameID) {
  const r = await fetch(`${PREFIX}/table/${tableID}/game/${gameID}/close`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
  });
  const res = await r.json();

  return (r.ok) ? res : Promise.reject(new HttpError(r, res));
}

export async function deleteGame(tableID, gameID) {
  const r = await fetch(`${PREFIX}/table/${tableID}/game/${gameID}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
    },
  });
  return (r.ok) ? null : Promise.reject(new HttpError(r, await r.json()));
}

function getMessageFromStatus(statusCode) {
  switch (statusCode) {
    case 401:
      return 'Unauthorized';
    case 403:
      return 'Forbidden';
    case 404:
      return 'Not found';
    case 202:
      return 'Accepted';
    default:
      return 'Unknown error';
  }
}

async function getResponseJSON(r) {
  let json = null;
  if (r.status === 202) return null;
  if (r.status === 204) return null;
  try {
    json = await r.json();
  } catch (err) {
    console.error(err);
    return { message: getMessageFromStatus(r.status) };
  }

  return json;
}



export async function admitUserJoin(tableID, userID) {
  const r = await fetch(`${PREFIX}/table/${tableID}/user/admit`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ userID }),
  });

  const res = await getResponseJSON(r);

  if (!r.ok) {
    return Promise.reject(new HttpError(r, res));
  }

  return res;

}

export async function rejectUserJoin(tableID, userID) {
  const r = await fetch(`${PREFIX}/table/${tableID}/user/reject`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ userID }),
  });

  const res = await getResponseJSON(r);

  if (!r.ok) {
    return Promise.reject(new HttpError(r, res));
  }

  return res;
}

export async function loadGame(params) {
  const r = await fetch(`${PREFIX}/table/${params.tableID}/game/${params.gameID}`);
  const res = await getResponseJSON(r);

  if (!r.ok) {
    return Promise.reject(new HttpError(r, res));
  }

  return res;
}

export async function createTable(title) {
  const r = await fetch(`${PREFIX}/table`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ title }),
  });

  const res = await r.json();

  return r.ok ? res : Promise.reject(new HttpError(r, res));
}

export async function createGuest(formData) {
  const r = await fetch(`${PREFIX}/guest-login`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
  const res = await r.json();

  return (r.ok) ? res : Promise.reject(new HttpError(r, res));
}

export async function requestTableJoin(tableID) {
  const r = await fetch(`${PREFIX}/table/${tableID}/join`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  });
  return (r.ok) ? {} : Promise.reject(new HttpError(r, await r.json()));
}

export async function setTableTitle(tableID, title) {
  const r = await fetch(`${PREFIX}/table/${tableID}/title`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ title }),
  });
  const res = await r.json();
  return (r.ok) ? res : Promise.reject(res);
}

export async function setGameTitle(tableID, gameID, title) {
  const r = await fetch(`${PREFIX}/table/${tableID}/game/${gameID}`, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ game: { title } }),
  });
  const res = await r.json();
  return (r.ok) ? res : Promise.reject(res);
}

export function useFetchUserTables() {
  return useFetch(`${PREFIX}/user-tables`, { queryKey: ['userTables'] });
}

export function useFetchBillingInfo() {
  return useFetchDataKey(`${PREFIX}/billing/info`, 'billingInfo', { queryKey: ['billingInfo'], refetchOnWindowFocus: false }, );
}


function getKeyString(queryKey) {
  if (Array.isArray(queryKey)) {
    return queryKey.join('-');
  }

  return queryKey;
}

export function useFetchToStorage(storage, { queryKey, queryFn }) {
  const keyString = getKeyString(queryKey);
  return useQuery({
    queryKey,
    queryFn: () => {
      const storagedData = storage.getItem(keyString);
      if (storagedData) {
        try {
          return JSON.parse(storagedData);
        } catch (err) {
          storage.removeItem(keyString)
        }
      }

      return queryFn()
        .then((data) => {
          storage.setItem(keyString, JSON.stringify(data));
          return data;
        }).catch((err) => {
          storage.removeItem(keyString);
          return Promise.reject(err);
        });
    }
  })
}

export function useFetchToLocalStorage({ queryKey, queryFn }) {
  return useFetchToStorage(window.localStorage, { queryKey, queryFn });
}

export function useFetchToSessionStorage({ queryKey, queryFn }) {
  return useFetchToStorage(window.sessionStorage, { queryKey, queryFn });
}

export function useFetchUserProfile() {
  return useFetch(`${PREFIX}/profile`, { queryKey: ['userprofile'] });
}
