import { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import ProfileContext from '../contexts/ProfileContext';
import { TitleContentLayout } from '../layouts/TitleContentLayout';
import { GameList } from '../components/GameList';

export default function TableOverview({ className }) {
  const { table } = useOutletContext();
  const profile = useContext(ProfileContext);
  return <TitleContentLayout className={className}>
    <TitleContentLayout.Heading>
      Games
    </TitleContentLayout.Heading>
    <GameList games={table.games} profile={profile} className="md:grid-cols-2 lg:grid-cols-3"/>
  </TitleContentLayout>
}
