import { InfoCard, InfoCardTitle } from './InfoCard';
import { FormattedDate } from './DateDisplay';
import { VotesTable } from './VotesTable';
import { DeckType } from './DeckType';
import { LinkButton } from './Button';
import { Emoji } from './Emoji';
import { twMerge } from 'tailwind-merge';

function JoinGameButton({ href }) {
  return <LinkButton className="
    text-sm
    border-0
    ring-2
    ring-offset-2
    ring-gray-300
    focus:ring-gray-500
    hover:ring-gray-400
    text-gray-700 bg-white
    px-3 py-1" to={href}>Join</LinkButton>;
}

function VotesTableIfClosed({ game, className }) {
  if (!game.closed) return <div className={twMerge('text-xl flex items-center justify-center', className)}>
    <span><span className="font-bold">{game.votes.length}</span> votes so far</span>
  </div>;

  return <VotesTable className={className} gameID={game.id} votes={game.votes} deckType={game.deckType} />
}

export function GameInfoCard({ game }) {
  const href = `/table/${game.tableID}/game/${game.id}`;
  return <InfoCard>
    <header className="text-lg p-4 border-b flex-none">
      <span className="flex justify-between">
        <span>
          <InfoCardTitle className="
            font-light text-black text-base
            hover:text-primary-600
            " href={href}>{game.title}</InfoCardTitle>
        </span>
        <span className="text-sm text-gray-500 leading-8 whitespace-nowrap">
          {game.deckType} <DeckType deckType={game.deckType} />
        </span>
      </span>
    </header>
    <VotesTableIfClosed className="p-4 grow" game={game} />

    <footer className="p-4 border-t flex justify-between items-center">
      <FormattedDate date={game.creaOn} />
      {game.closed ?
        <Emoji label="locked" title="game closed" className="text-sm" />
        : <JoinGameButton href={href} />}
    </footer>
  </InfoCard>
}
