import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

function getClassNames(isSelected, isClosed) {
  let classes = 'p-2 border-l hover:border-gray-400 transition-colors';

  if (isSelected) {
    classes = twMerge(classes, 'border-primary-400 hover:border-primary-600 border-l-2');
  }

  if (isClosed) {
    classes = twMerge(classes, 'text-gray-400 hover:text-gray-500');
  }

  return classes;
}


export function Games({ games, tableID, gameID, className }) {
  if (!games) return null;
  if (games.length === 0) return <p>No games yet</p>;
  const gamesList = games
    .sort((a, b) => new Date(b.creaOn) - new Date(a.creaOn))
    .map(game => {
      const selected = gameID === game.id;
      const classes = getClassNames(selected, game.closed);
      return <li className={classes} key={game.id}>
        <Link to={`/table/${tableID}/game/${game.id}`}>{game.title}</Link>
      </li>;
    });
  return <>
    <h3 className="text-2xl mb-2">Games</h3>
    <ol className={className}>{gamesList}</ol>
  </>
}
