import { twMerge } from 'tailwind-merge';

const INPUT_CLASSES = `
  text-base
  font-normal
  leading-6
  block w-full rounded-md py-1.5 px-2
  text-gray-900
  shadow-sm
  outline-0
  border-0
  appearance-none
  ring-1 ring-inset ring-gray-300
  focus:ring-1 focus:ring-inset focus:ring-primary-600
  focus:outline-none
  placeholder:text-gray-400
  sm:text-sm sm:leading-6
`;

export function Input({ className, ...props }) {
  return <input className={twMerge(INPUT_CLASSES, className)} {...props} />;
}
