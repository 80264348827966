import { useOutlet } from "react-router-dom";

const Navigation = () => (
  <nav>
  </nav>
);

export const AuthLayout = () => {
  const outlet = useOutlet();

  return (
    <>
    <Navigation />
      {outlet}
    </>
  );
};
