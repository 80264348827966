import {
  useQueryClient
} from '@tanstack/react-query';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  requestTableJoin,
  useAdmitUserJoinMutation,
  useCloseGameMutation,
  useDeleteGameMutation,
  useLoadTable,
  useRejectUserJoinMutation,
  useSetGameTitleMutation,
  useSetTableTitleMutation,
  useVoteMutation
} from '../api/api';
import { PokerTable } from '../components/PokerTable';
import { HandleRequestError } from '../components/RequestError';
import ProfileContext from '../contexts/ProfileContext';
import { useWss } from '../hooks/useWss';


function wsURL(path) {
  const url = new URL(path, window.location);
  url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
  return url.href;
}

function isUserTableOwner(profile, table) {
  return profile.sub === table.creaBy;
}

function getUpdatedGames(currentGames, game) {
  return currentGames.map((g) => {
    if (g.id === game.id) return game;
    return g;
  });
}

function getMutationErrors(...mutations) {
  return mutations.filter((mut) => mut.isError).map((mut) => mut.error);
}

export default function ShowTable() {
  const profile = useContext(ProfileContext);
  const params = useParams();
  const { tableID, gameID } = params;

  useWss(wsURL(`/ws/table/${tableID}`));
  const queryClient = useQueryClient();
  const { data: table, isError, isPending, error } = useLoadTable(tableID);
  const navigate = useNavigate();
  const setGames = (setter) => {
    queryClient.setQueryData(['table', tableID], (current) => {
      const newGames = setter(current?.games || []);
      return { ...current, games: newGames };
    });
  };

  const admitUserJoinMutation = useAdmitUserJoinMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['table', tableID] });
    },
  });

  const rejectUserJoinMutation = useRejectUserJoinMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['table', tableID] });
    },
  });

  const tableTitleMutation = useSetTableTitleMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(['table', tableID], (current) => {
        return { ...current, title: data.table.title };
      });
    },
  });

  const voteMutation = useVoteMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(['table', tableID], (current) => {
        return { ...current, games: getUpdatedGames(current.games, data.game) };
      });
    },
  });

  const revealCardMutation = useCloseGameMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(['table', tableID], (current) => {
        return { ...current, games: getUpdatedGames(current.games, data.game) };
      });
    },
  });

  const setGameTitleMutation = useSetGameTitleMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(['table', tableID], (current) => {
        return { ...current, games: getUpdatedGames(current.games, data.game) };
      });
    },
  });

  const deleteGameMutation = useDeleteGameMutation({
    onSuccess: () => {
      queryClient.setQueryData(['table', tableID], (current) => {
        return { ...current, games: current.games.filter((g) => g.id !== gameID) };
      });
      navigate(`/table/${tableID}`);
    },
  });


  if (isError) {
    if (error.response.status === 403) {
      requestTableJoin(tableID);
    }
    return <HandleRequestError err={error} />
  }

  if (isPending) {
    return <div>Loading...</div>;
  }

  if (!table) return null;

  const mutationErrors = getMutationErrors(
    admitUserJoinMutation,
    rejectUserJoinMutation,
    tableTitleMutation,
    voteMutation,
    revealCardMutation,
    setGameTitleMutation,
    deleteGameMutation,
  );

  return <PokerTable
      table={table}
      profile={profile}
      canEdit={isUserTableOwner(profile, table)}
      gameID={gameID}
      tableID={tableID}
      errors={mutationErrors}
      onTableTitleChange={(title) => tableTitleMutation.mutate({ tableID, title })}
      onGameTitleChange={(title) => setGameTitleMutation.mutate({ tableID, gameID, title })}
      onVote={(vote) => voteMutation.mutate({ tableID, gameID, vote })}
      onRevealCard={() => revealCardMutation.mutate({ tableID, gameID })}
      onDeleteGame={() => deleteGameMutation.mutate({ tableID, gameID })}
      onRejectTableUser={(sub) => rejectUserJoinMutation.mutate({ tableID, userID: sub })}
      onAdmitTableUser={(sub) => admitUserJoinMutation.mutate({ tableID, userID: sub })}
      setGames={setGames}
      users={table.users}
      games={table.games}
    />
    ;
}
