import { useContext } from 'react';
import { ReactComponent as Check } from '../components/assets/check.svg';
import ProfileContext from '../contexts/ProfileContext';
import { BUTTON_CLASSES, LinkButton } from '../components/Button';
import { twMerge } from 'tailwind-merge';

function Plan({ className, children }) {
  return <div className={twMerge("rounded-3xl shadow-lg bg-white p-12 border flex flex-col justify-between", className)}>{children}</div>
}

function PlanTitle({ className, children }) {
  return <h3 className={twMerge("font-bold text-primary-600 pb-4", className)}>{children}</h3>
}

function PlanPriceMonthly({ className, children }) {
  return <p className={twMerge("text-4xl font-bold text-gray-800 mb-10", className)}>{children}<span className="text-sm text-gray-500"> / month</span></p>
}

function PlanTeaser({ className, children }) {
  return <p className={twMerge("text-gray-700 mb-2", className)}>{children}</p>
}

function PlanFeatures({ className, children }) {
  return <ul className={twMerge("text-gray-700", className)}>{children}</ul>
}

function PlanFeature({ className, children }) {
  return <li className={twMerge("flex items-center space-x-2 mb-2", className)}>
    <Check className="w-4 h-4 text-primary-600" />
    <span>{children}</span>
  </li>
}

function CurrentPlan({ className, children }) {
  return <span className={twMerge("px-2 py-1 bg-primary-200 text-primary-600 text-sm font-bold rounded-xl float-right", className)}>{children}</span>
}

function FreePlan({ isCurrentPlan, hasPlan, currency }) {
  return <Plan>
    <PlanTitle>
      {isCurrentPlan ? <CurrentPlan>Current plan</CurrentPlan> : null}
      Free
    </PlanTitle>
    <PlanPriceMonthly><FreePrice currency={currency} /></PlanPriceMonthly>
    <PlanTeaser>Free access with limited tables and games included</PlanTeaser>

    <PlanFeatures>
      <PlanFeature>Unlimited users</PlanFeature>
      <PlanFeature>History of 2 last tables only</PlanFeature>
      <PlanFeature>2 tables / month</PlanFeature>
      <PlanFeature>5 games / table</PlanFeature>
    </PlanFeatures>

    { isCurrentPlan || hasPlan ? null : <LinkButton className="mt-10 w-full text-center" to="/login">Get Started</LinkButton> }
  </Plan>
}

function ProPrice({currency}) {
  switch (currency) {
    case 'USD':
      return '$4.99';
    case 'EUR':
      return '€4.99';
    case 'HUF':
      return '1500 Ft'
    default:
      return ProPrice({currency: 'USD'});
  }
}

function FreePrice({currency}) {
  switch (currency) {
    case 'USD':
      return '$0';
    case 'EUR':
      return '€0';
    case 'HUF':
      return '0 HUF'
    default:
      return FreePrice({currency: 'USD'});
  }
}


function ProPlan({ isCurrentPlan, currency }) {
  const href = `/api/subscribe?plan=pro&currency=${currency}`;
  return <Plan>
    <PlanTitle>
        {isCurrentPlan ? <CurrentPlan>Current plan</CurrentPlan> : null}
        Pro
    </PlanTitle>
    <PlanPriceMonthly><ProPrice currency={ currency } /></PlanPriceMonthly>

    <PlanTeaser>Unlimited access to all features</PlanTeaser>

    <PlanFeatures>
      <PlanFeature>Unlimited users</PlanFeature>
      <PlanFeature>History of all games</PlanFeature>
      <PlanFeature>Unlimited tables</PlanFeature>
      <PlanFeature>Unlimited games</PlanFeature>
    </PlanFeatures>
    {isCurrentPlan ? null : <a className={twMerge(BUTTON_CLASSES, "mt-10 w-full text-center")} href={href}>Buy plan</a>}
  </Plan>

}

/*
function CurrencyButton({ isSelected, setCurrency, value, className }) {
    const classes = twMerge("text-black rounded bg-gray-300 px-2 py-1 mx-1 text-sm hover:ring-2 hover:bg-gray-100", isSelected ? 'bg-primary-600 text-white hover:bg-primary-500' : '', className);
    return <button className={classes} type="button" onClick={() => setCurrency(value)}>{value}</button>
}
*/

function Pricing({ className }) {
  const profile = useContext(ProfileContext);

  const [currency] = 'USD';

  const profileProduct = profile?.product;
  // const usersCurrency = profile?.currency;

  return (
    <div className={className}>
      <div className="text-center pt-10 pb-64 bg-black bg-no-repeat bg-gradient-radial from-primary-400 from-20% bg-[center_bottom_-8rem] via-black to-black rounded-3xl">
        <h2 className="text-4xl text-white font-bold py-10">Pricing</h2>
        <p className="text-gray-300 mb-4">Upgrade to Pro plan to unlock unlimited access to all features!</p>
      </div>
      <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 -mt-72 w-full pt-24 px-5 lg:px-10">
        <FreePlan isCurrentPlan={profile && !profileProduct} hasPlan={!!profileProduct} currency={currency} />
        <ProPlan isCurrentPlan={profileProduct === 'pro'} currency={currency} />
      </div>
    </div>
  );
}

export default function Index() {
  return (
    <>
      <div className="pt-6 mx-auto w-3/5 mb-20">
        <h2 className="text-center text-4xl mb-10">Agile Estimation for Remote Teams</h2>
        <article className="text-center text-lg mb-6">
          <p>
            Estimate, Collaborate, Succeed – Anywhere, Anytime.<br />
            PlanningPoker.app simplifies agile estimation for remote teams, ensuring precise sprint planning and seamless collaboration, no matter the distance. Empower your remote workforce with PlanningPoker.app today!
          </p>
        </article>
      </div>
      <div className="grid md:grid-cols-3 gap-8 mb-20">
        <div>
          <h3 className="font-bold mb-2">🚀 Supercharge Your Sprint Planning</h3>
          <p className="text-gray-700 tracking-wide">Streamline your sprint planning sessions with an intuitive interface designed for agile teams. Experience lightning-fast estimation that keeps your projects on track and ahead of schedule.</p>
        </div>
        <div>
          <h3 className="font-bold mb-2">🎯 Accurate Estimations, Every Time:</h3>
          <p className="text-gray-700 tracking-wide">Bid farewell to overestimations and underestimations. With PlanningPoker.app, your team can make data-driven decisions, ensuring tasks are allocated the right resources and completed within the sprint timeframe.</p>
        </div>
        <div>
          <h3 className="font-bold mb-2">⏱️ Save Time, Boost Productivity:</h3>
          <p className="text-gray-700 tracking-wide">Say goodbye to lengthy estimation meetings and hello to efficient, focused discussions. PlanningPoker.app saves your team time, enabling you to concentrate on what truly matters – delivering exceptional results.</p>
        </div>
      </div>
      <Pricing />
    </>
  )
}
