import { GameInfoCard } from './GameInfoCard';
import { twMerge } from 'tailwind-merge';

export function GameList({ games, profile, className }) {
  if (games.length === 0) return null;
  const classes = twMerge('grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4', className);
  return (
  <div className={classes}>
    {games.map((game) => <GameInfoCard key={game.id} game={game} profile={profile}  />)}
  </div>
  )
}


