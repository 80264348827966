import { Link, Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { EditTitle } from './EditableTitle';
import { Games } from './Games';
import { ErrorNotification } from './Notification';
import { HandleRequestError } from './RequestError';
import { TableUsers } from './TableUsers';
import { LinkButton } from '../components/Button';

function TableTitle({ canEdit, tableID, gameID, title, onSetTableTitle, className }) {
  const classes = twMerge("text-xl lg:text-2xl xl:text-3xl font-normal mb-5", className)
  if (canEdit) {
    return <h2 className={classes}>
      <EditTitle title={title} id={tableID} onSubmit={onSetTableTitle}>
        {gameID ? null : <LinkButton className="float-right" to={`/table/${tableID}/create-game`}>Create game</LinkButton>}
        <Link className="hover:text-primary-600 transition-colors" to={`/table/${tableID}`}>{title}</Link>
      </EditTitle>
    </h2>
  }

  return <h2 className={classes}>
    <Link className="hover:text-indigo-600 transition-colors" to={`/table/${tableID}`}>{title}</Link>
  </h2>;
}

function Errors({ errors }) {
  return errors.map((err, i) => <ErrorNotification key={`n-${i}`} timeout="0" className="mb-10">
    <HandleRequestError className="bg-transparent border-green-300" err={err} />
  </ErrorNotification>)
}

export function PokerTable({
  table,
  profile,
  canEdit,
  tableID,
  gameID,
  setError,
  games,
  setGames,
  users,
  errors,
  onTableTitleChange,
  onVote,
  onRevealCard,
  onGameTitleChange,
  onDeleteGame,
  onAdmitTableUser,
  onRejectTableUser,
}) {

  return (<>
    <TableTitle canEdit={canEdit} tableID={tableID} gameID={gameID} title={table.title} onSetTableTitle={(e) => onTableTitleChange(e.target.elements[0].value)} />
    <Errors errors={errors} />
    <div className="flex flex-col md:flex-row">
      <nav className="w-full md:w-52 lg:w-60 pr-4 shrink-0 order-last md:order-first">
        <h3 className="text-2xl mb-2">Players</h3>
        <TableUsers
          users={users}
          profile={profile}
          canEdit={canEdit}
          onRejectTableUser={onRejectTableUser}
          onAdmitTableUser={onAdmitTableUser}
          className="mb-10"
        />
        {gameID ? <Games className="mb-6" tableID={tableID} games={games} gameID={gameID} /> : null}
      </nav>
      <div className="grow mb-4">
        <Outlet context={{
          table,
          onVote,
          onRevealCard,
          onGameTitleChange,
          onDeleteGame,
          setError,
          profile,
          games,
          setGames,
        }} />
      </div>
    </div>
  </>
  )
}
