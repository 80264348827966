import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import React from 'react';
import { Link, Route, Routes } from "react-router-dom";
import { useFetchUserProfile } from './api/api';
import './App.css';
import { ReactComponent as Logo } from './components/assets/logo.svg';
import { CreateGame } from './components/CreateGame';
import { LoggedOut } from './components/LoggedOut';
import { Login } from './components/Login';
import { ProtectedRoute } from './components/ProtectedRoute';
import ProfileContext from './contexts/ProfileContext';
import { AuthLayout } from './layouts/AuthLayout';
import Account from './routes/Account';
import CreateTable from './routes/CreateTable';
import Index from './routes/Index';
import ShowGame from './routes/ShowGame';
import ShowTable from './routes/ShowTable';
import Subscribe from './routes/Subscribe';
import TableOverview from './routes/TableOverview';
import Tables from './routes/Tables';

const queryClient = new QueryClient();

function LoginLogout({ profile, onLoginClick }) {
  if (profile) {
    return <>
      <Link to="/account" className="text-sm hover:underline mr-2">{profile.nickname}</Link>
      <a className="font-normal text-sm" href="/api/logout">Logout</a>
    </>;
  }

  function showLogin(e) {
    e.preventDefault();
    onLoginClick();
  }

  return <Link className="font-normal text-sm" to="/login" onClick={showLogin}>Log in →</Link>;
}

function LoginModal({ setShowLogin }) {
  return <>
    <div className="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="z-10 items-end min-h-screen mt-0 pb-1 text-center lg:pt-4 lg:px-4 lg:pb-20">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="z-20 lg:grid place-content-center mt-2">
          <div className="bg-white w-5/6 lg:w-2/3 mx-auto p-3 lg:p-10 lg:mt-20 rounded-lg relative">
            <button onClick={() => setShowLogin(false)} className="absolute top-0 right-4 p-2 hover:text-primary-600">X</button>
            <Login />
          </div>
        </div>
      </div>
    </div>
  </>
}

function RightNavBar({ profile, onLoginClick }) {
  return <ul>
    <li>
      <LoginLogout profile={profile} onLoginClick={onLoginClick} />
    </li>
  </ul>;
}

function MenuNavBar({ profile }) {
  if (!profile) return null;
  return <div className="flex justify-between">
    <div className="flex items-center space-x-3 lg:pr-16 pr-6 font-bold text-sm">
      <Link to="/tables" className="text-gray-800 hover:text-primary-600 transition-colors">Tables</Link>
    </div>
  </div>;
}

function AppWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  )
}


function App() {
  const setSessionData = () => { };
  const { data, isError } = useFetchUserProfile();
  const [showLogin, setShowLogin] = React.useState(false);

  const profile = (!isError && data) || null;

  return (<ProfileContext.Provider value={profile}>
    <div className="text-sm 2xl:container 2xl:mx-auto bg-orange-500 text-white text-center font-bold px-7 py-0.5">running in test mode</div>
    <header className="2xl:container 2xl:mx-auto">
      <div className="bg-white rounded py-5 px-7 flex justify-between">
        <Link className="font-normal text-2xl leading-6 text-gray-800 hover:text-primary-600 transition-colors" to="/"><Logo className="h-7 w-7 inline-block text-primary-600" /> <span className="hidden sm:inline">Planning Poker</span></Link>
        <MenuNavBar profile={profile} />
        <RightNavBar profile={profile} onLoginClick={() => setShowLogin(true)} />
      </div>
    </header>
    <main className="2xl:container 2xl:mx-auto bg-white px-7 pb-7">
      <Routes>
        <Route index path="/" element={<Index profile={profile} />} />
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />}></Route>
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/logged-out" element={<LoggedOut setSessionData={setSessionData} />}></Route>
        </Route>
        <Route
          path="/subscribe"
          element={
            <ProtectedRoute>
              <Subscribe />
            </ProtectedRoute>
          } />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          } />
        <Route
          path="/tables"
          element={
            <ProtectedRoute>
              <Tables profile={profile} />
            </ProtectedRoute>
          } />
        <Route
          path="/table"
          element={
            <ProtectedRoute>
              <CreateTable />
            </ProtectedRoute>
          } />
        <Route
          path="/table/:tableID"
          element={
            <ProtectedRoute>
              <ShowTable />
            </ProtectedRoute>
          }>
          <Route
            path="/table/:tableID/game/:gameID"
            element={
              <ProtectedRoute>
                <ShowGame profile={profile} />
              </ProtectedRoute>
            } />
          <Route
            path="/table/:tableID/create-game"
            element={<CreateGame profile={profile} />} />
          <Route
            path="/table/:tableID"
            element={<TableOverview />} />
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </main>
    {showLogin && <LoginModal setShowLogin={setShowLogin} />}
  </ProfileContext.Provider>
  )
}

export default AppWrapper;
// export const routes = createBrowserRouter(
//   createRoutesFromElements(
//     <>
//     </>
//   )
// );
