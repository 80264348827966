import { ReactComponent as ClipboardAdd } from './assets/clipboard-add.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export function CopyButton({ text, className, ...props }) {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const classes = twMerge('w-5 h-5 focus:ring-0 inline-block hover:scale-150 transition-all cursor-pointer', className);

  if (copied) {
    setTimeout(() => setCopied(false), 2000);

    return <Check className={twMerge(classes, 'text-green-500')} title="copied!" />;
  }

  return <ClipboardAdd onClick={copyToClipboard} className={classes}  title="copy to clipboard" {...props} />;
}
