import { getCards } from '../../data/cards';
import { ListOfCards } from '../ListOfCards';
import { RevealCards } from './RevealCards';
import { Votes } from './Votes';

export function EditingControls({
  game,
  table,
  onSelect,
  onRevealCard,
  onDeleteVote,
  profile,
  className,
}) {
  const onSelectExec = (value) => {
    if (game.closed) return;
    onSelect(value);
  }

  const selectedVote = game.votes.find((vote) => {
    return vote.user.sub === profile.sub;
  });

  return (<div className={className}>
    <div className="text-right mb-4">
      <RevealCards onRevealCard={onRevealCard} game={game} profile={profile} table={table} />
    </div>
    <Votes
      game={game}
      profile={profile}
      selectedVote={selectedVote}
      revealed={game.closed}
      onDeleteVote={onDeleteVote}
    />
    {!game.closed && <ListOfCards
      cards={getCards(game.deckType)}
      onSelect={onSelectExec}
      onDeleteVote={onDeleteVote}
      profile={profile}
      selected={selectedVote?.vote}
    />}
  </div>);
}
